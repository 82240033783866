import styled from '@emotion/styled'
import colors from '../../styles/color'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  font-weight: 700;

  a {
    display: block;
    width: 100%;
  }
`

export const Text = styled.div`
  font-size: 24px;
  color: ${colors.GRAY};
  margin-left: 16px;
  text-align: right;
`

export const Title = styled.div`
  font-size: 33px;
  color: ${colors.GRAY};
  text-align: center;
  margin-bottom: 60px;
`
