import React, { useEffect, useState } from 'react'
import { Wrapper, Container, Logo, Overlay } from './style'
import Image from '../../image'

const PageTransition = () => {
  const [isAnimationStart, setStartAnimation] = useState(false)

  useEffect(() => {
    setStartAnimation(true)
  }, [isAnimationStart])

  return (
    <Wrapper>
      <Overlay />
      <Container isAnimationStart={isAnimationStart} />
      <Logo>
        <Image filename="logo/logo_new_w.png" />
      </Logo>
    </Wrapper>
  )
}

export default PageTransition
