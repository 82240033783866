import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'

export const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
  color: ${props => (props.color === 'white' ? '#fff' : '#1c1c1c')};
`

export const EnLabel = styled.div`
  font-family: linotype-didot, serif;
  font-size: 18px;
  @media (min-width: ${breakpoints.m}px) {
    font-size: 24px;
  }
`

export const Label = styled.h2`
  font-size: 24px;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 30px;
  }
`
