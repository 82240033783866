import React from 'react'
import { EnLabel, Label, Wrapper } from './styles'

const CategoryTitle = ({ en, label, color = 'black' }) => {
  return (
    <Wrapper color={color}>
      <EnLabel>{en}</EnLabel>
      <Label>{label}</Label>
    </Wrapper>
  )
}

export default CategoryTitle
