import styled from '@emotion/styled'
import colors from '../../../styles/color'
import { Z_INDEX_PAGE_TRANSITION } from '../../../const'
import { keyframes } from '@emotion/core'
import { breakpoints } from '../../../utils/styles'

const fadeOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }
  50% {
    opacity: 1;
    visibility: visible;  
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`

const fadeOutLogo = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }
  60% {
    opacity: 1;
    visibility: visible;  
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX_PAGE_TRANSITION};
`

export const Container = styled.div`
  position: fixed;
  top: 100vh;
  left: 0;
  width: 100%;
  height: calc(100vh * 2.5);
  ${props => props.isAnimationStart && 'transform:translateY(-200%)'};
  transition: 1.4s transform cubic-bezier(0.6, 0.07, 0.8, 0.99);
  background-color: ${colors.BLACK};
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${colors.WHITE};
  animation: ${fadeOut} 1.8s ease-in-out;
  animation-fill-mode: forwards;
`

export const Logo = styled.div`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 60px;
  animation: ${fadeOutLogo} 1.1s ease-in-out;
  animation-fill-mode: forwards;

  @media (min-width: ${breakpoints.m}px) {
    width: 80px;
  }
`
