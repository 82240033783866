import React from 'react'
import { Container, Text, Title } from './styles'
import { Link } from 'gatsby'

const NextLink = ({ label, path }) => {
  return (
    <Container>
      <Link to={path}>
        <Title>{label}</Title>
        <Text>Next Page →</Text>
      </Link>
    </Container>
  )
}

export default NextLink
