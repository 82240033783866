import styled from '@emotion/styled'
import line from '../../../images/title/line_gold.png'
import { breakpoints } from '../../../utils/styles'

export const Wrapper = styled.div`
  text-align: center;
  color: #fff;
  margin-bottom: 24px;
`

export const EnLabel = styled.div`
  font-family: linotype-didot, serif;
  font-size: 15px;
  padding-left: 2px;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 24px;
  }
`

export const Label = styled.div`
  font-size: 24px;
  padding-left: 2px;
  @media (min-width: ${breakpoints.m}px) {
    font-size: 34px;
  }
`

export const Line = styled.div`
  width: 60px;
  height: 3px;
  margin: 0 auto;
  background-image: url(${line});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`
