import styled from '@emotion/styled'
import washi from '../images/bg/washi_bk.jpg'
import { Link } from 'gatsby'
import color from './color'
import { breakpoints } from '../utils/styles'

// ブランドストーリー、選び方、こだわり、身に付け方で共通のスタイル

export const Wrapper = styled.div`
  background-color: ${color.BLACK};
`

export const Content = styled.div`
  padding: 40px 12px;

  @media (min-width: ${breakpoints.m}px) {
    max-width: 800px;
    margin: 0 auto;
  }
`

export const HeadLine = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-image: url(${washi});
  background-size: cover;
  @media (min-width: ${breakpoints.m}px) {
    padding: 30px;
  }
`

export const ImageWrapper = styled.div`
  @media (min-width: ${breakpoints.m}px) {
    max-width: 800px;
    margin: 60px auto 0;
    border: 50px solid ${color.WHITE};
  }
`

export const HeadContent = styled.div`
  margin-bottom: 40px;

  @media (min-width: ${breakpoints.m}px) {
    margin-bottom: 60px;
  }
`

export const Block = styled.div`
  margin-bottom: 60px;
`

export const BlockImage = styled.div`
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.m}px) {
    width: 500px;
    margin: 0 auto 28px;
  }
`

export const BackLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  @media (min-width: ${breakpoints.m}px) {
    font-size: 20px;
  }
`

export const PageName = styled.div`
  @media (min-width: ${breakpoints.m}px) {
    display: none;
  }
`

export const Text = styled.div`
  color: #fff;
  @media (min-width: ${breakpoints.m}px) {
    font-size: 22px;
    text-align: center;
  }
`
