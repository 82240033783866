import React from 'react'
import { EnLabel, Line, Label, Wrapper } from './styles'

const SubTitle = ({ en, label }) => {
  return (
    <Wrapper>
      <EnLabel>{en}</EnLabel>
      <Line />
      <Label>{label}</Label>
    </Wrapper>
  )
}

export default SubTitle
