import React from 'react'
import {
  BackLink,
  Block,
  BlockImage,
  Content,
  HeadContent,
  HeadLine,
  ImageWrapper,
  PageName,
  Text,
  Wrapper,
} from '../styles/brandStoryPage'
import Image from '../components/image'
import CategoryTitle from '../components/Title/CategoryTitle'
import SubTitle from '../components/Title/SubTitle'
import SEO from '../components/seo'
import NextLink from '../components/NextLink'
import PageTransition from '../components/Animation/PageTransition'

const CONTENT_LIST = [
  {
    en: 'Enjoy',
    label: '楽しむ',
    image: 'photo/kimono_2.jpg',
    text:
      '天然石の持つ魅力を楽しむ。石の持つ意味は一旦置いておいて、身に付けたときの感触、色や形、光の反射などで違った表情を見せてくれます。',
  },
  {
    en: 'Introspection',
    label: '自分を見つめる',
    image: 'photo/see.jpg',
    text:
      '身に付けている時にどんな事があったのか。起きた現象や心の変化に意識を向けてみる。その時に心で感じた事や感情が人生を豊なものへと変えるキッカケに繋がっていきます。',
  },
  {
    en: 'Message',
    label: 'メッセージ',
    image: 'photo/message.jpg',
    text:
      '天然石の持つ自然のエネルギーを通して、今自分に必要なメッセージを知る。アクセサリーを身につけて神社やお寺でおみくじを引きます。おみくじに書かれている文字を指でなぞっていきます。ビリビリと感じたり、反発する様な感じ、違和感がある所が見つかればそこに書かれている事がメッセージとなります。地に足をつけ、自分を見つめ直し、その言葉を意識して生活してみてください。',
  },
]

const HowToWearPage = () => {
  return (
    <>
      <SEO
        title="身に付け方"
        keywords={[`高天原`, `アクセサリー`, `天然石`, `accessory`, `stone`]}
      />
      <Wrapper>
        <HeadLine>
          <BackLink to="/feature">←</BackLink>
          <PageName>身に付け方</PageName>
          <div></div>
        </HeadLine>
        <ImageWrapper>
          <Image filename="photo/how-to-wear.jpg" />
        </ImageWrapper>
        <Content>
          <HeadContent>
            <CategoryTitle en="How To Wear" label="身に付け方" color="white" />
            <Text>
              高天原のアクセサリーのお勧めの使い方をご紹介します。今必要だと感じたら手に取ってみて下さい。ある物事と向き合う時、支えになってくれることでしょう。
            </Text>
          </HeadContent>

          {CONTENT_LIST.map(({ en, label, image, text }) => {
            return (
              <Block key={en}>
                <SubTitle en={en} label={label} />
                <BlockImage>
                  <Image filename={image} />
                </BlockImage>
                <Text>{text}</Text>
              </Block>
            )
          })}
          <NextLink label="選び方" path={'/choice'} />
        </Content>
        <PageTransition />
      </Wrapper>
    </>
  )
}

export default HowToWearPage
